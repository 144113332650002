@tailwind base;
@tailwind components;
@tailwind utilities;

/* Quiche Sans fonts */
@font-face {
    font-family: 'Quiche-Sans';
    src: url('../src/assets/fonts/Quiche_Sans/Quiche_Sans_Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Quiche-Sans';
    src: url('../src/assets/fonts/Quiche_Sans/Quiche_Sans_Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Quiche-Sans';
    src: url('../src/assets/fonts/Quiche_Sans/Quiche_Sans_Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Quiche-Sans';
    src: url('../src/assets/fonts/Quiche_Sans/Quiche_Sans_Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* General Sans fonts */
@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-Variable.woff2') format('woff2'),
         url('../src/assets/fonts/General_Sans/GeneralSans-Variable.woff') format('woff'),
         url('../src/assets/fonts/General_Sans/GeneralSans-Variable.ttf') format('truetype');
        font-weight: 200 700;
        font-display: swap;
        font-style: normal;
}

@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-VariableItalic.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-VariableItalic.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-VariableItalic.ttf') format('truetype');
    font-weight: 200 700;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-Extralight.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Extralight.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-ExtralightItalic.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-ExtralightItalic.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-ExtralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-Light.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Light.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-LightItalic.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-LightItalic.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-Regular.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Regular.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-Italic.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Italic.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-Medium.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Medium.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-MediumItalic.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-MediumItalic.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-Semibold.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Semibold.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-SemiboldItalic.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-SemiboldItalic.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-Bold.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Bold.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../src/assets/fonts/General_Sans/GeneralSans-BoldItalic.woff2') format('woff2'),
        url('../src/assets/fonts/General_Sans/GeneralSans-BoldItalic.woff') format('woff'),
        url('../src/assets/fonts/General_Sans/GeneralSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}

/* End fonts family declare */
/* ***************************************************************************** */
/* ***************************************************************************** */
/* ***************************************************************************** */

input, button {
    font-family: GeneralSans;
}

input {
    font-size: 12px;
    line-height: 14px;
    font-family: GeneralSans;
    font-weight: 500;
}

input::placeholder {
    color: white;
}

.header4 {
    font-family: Quiche-Sans;
    font-weight: 400;
    color: #FFF;
}

.content4 {
    font-family: GeneralSans;
    font-weight: 400;
    color: #FFF;
}

.header .p-menuitem-content:hover, 
.header .p-menuitem-content a.active {
    background-color: #897151;
}

.header .p-menuitem-content {
    overflow: hidden;
}

.header .p-menubar-root-list {
    gap: 25px;
    display: flex;
}

/* Hide the default collapse button */
.p-menubar-button {
    display: none; 
}

/* Custom collapse button next to end content */
.p-menubar-end .p-menubar-button {
    box-shadow: none;
    border-radius: 5px;
    padding: 12px 15px 10px 15px;
    background-color: #897151;
    width: auto;
    color: white;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}


@media (max-width: 960px) {
    .p-menubar-root-list {
        top: 62px;
        background-color: #18140c !important;
        width: 209px;
        padding: 20px;
        border-radius: 12px;
        overflow: hidden;
        display: none !important;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex !important;
        left: auto;
        right: 0px;
    }
}


/* Table customize */

.p-datatable-table .p-datatable-thead tr th {
    background-color: #5B5549;
    color: #FFF;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    font-family: GeneralSans;
}

.p-datatable-table .p-datatable-tbody tr td {
    background-color: #18140c;
    color: #FFF;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: GeneralSans;
}

.acts .p-datatable-table .p-datatable-tbody tr td {
    background-color: #131009;
}

.p-datatable-table .p-datatable-tbody tr {
    border-top: 1px solid #5B5549;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

.vox-textarea::placeholder {
    color: #FFFFFF;
}


/* select tag style */
option {
    background-color: #070603;
    padding: 15px 20px;
}

select {
    -webkit-padding-end: 20px;
}

/* dropdown style */

.p-dropdown-panel {
    background-color: #0706034D;
}

.p-dropdown-panel .p-dropdown-item-label {
    color: #FFF;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    width: 100%;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #0706034D !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background-color: #070603 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0px !important;
}

.p-dropdown-custom-label {
    padding: 0.75rem 1.25rem;
}

.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    background: none !important;
}


/* calendar style */

.rdrCalendarWrapper {
    background-color: #13100a !important;
}

.rdrDayNumber span, .rdrWeekDay, .rdrMonthAndYearPickers select, .rdrMonthAndYearPickers select {
    color: #FFF !important;
}

.rdrDayPassive .rdrDayNumber span {
    color: #5B5549 !important
}

.rdrInRange {
    color: #725e43 !important;
}

.rdrStartEdge, .rdrEndEdge {
    color: #897151 !important;
}

.rdrNextPrevButton, .rdrNextPrevButton {
    background-color: transparent !important;
}

.rdrMonthAndYearWrapper .rdrPprevButton i {
    border-color: transparent #897151 transparent transparent;
}

.rdrMonthAndYearWrapper .rdrNextButton i {
    border-color:  transparent transparent transparent #897151;
}